/** @jsx jsx */
import { jsx } from "theme-ui";
import "./Switch.css";

const Switch = ({ isOn, handleToggle, onColor }) => {
  return (
    <div
      sx={{
        mr: [0,1,2],
        pr: 1,
        // display: 'flex'
        position: "absolute",
        right: 0,
        top: 0,
      }}
    >
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;
