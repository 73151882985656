/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

import Container from "../components/Container";

export default () => {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        variant: "nav",
        // flexDirection: "row",
        borderTop: "1px solid",
        borderColor: "muted",
        textAlign: "center",
        fontSize: [1],
        justifyContent: "space-between"
        // mt: 2,
        // mx: 3
      }}
    >
      <div>© {new Date().getFullYear()}</div>
      <Link to="/">Home</Link>
      <Link to="/impressum">Impressum</Link>
    </Container>
  );
};
