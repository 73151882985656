/** @jsx jsx */
import { useState } from "react";
import { jsx, useColorMode } from "theme-ui";
import { NavLink } from "@theme-ui/components";
// import { Link } from "gatsby"

import Logo from "../components/Logo";
import Switch from "./Switch";
// import Container from "../components/Container";
// import PropTypes from "prop-types"

const Header = () => {
  const [colorMode, setColorMode] = useColorMode();

  const [value, setValue] = useState(false);

  return (
    <header
      sx={{
        display: "flex",
        alignItems: "center",
        variant: "nav",
        flexDirection: "column",
        borderBottom: "1px solid",
        borderColor: "muted",
        textAlign: "center",
        // mt: 2,
        // mx: 3
      }}
    >
      <NavLink
        href="/"
        sx={{
          p: 0,
        }}
      >
        <Logo />
      </NavLink>

      {/* <NavLink
        href='/'
        sx={{ variant: 'styles.navlink' }}>
        Home
      </NavLink> */}

      {/* <div sx={{ mx: 'auto' }} /> */}

      <div
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          paddingBottom: 0,
        }}
      >
        <NavLink
          href="/"
          sx={{
            variant: "links.nav",
          }}
        >
          Kanzlei
        </NavLink>

        <NavLink href="/rechtsanwaelte" sx={{ variant: "links.nav" }}>
          Rechtsanwälte
        </NavLink>

        <NavLink href="/rechtsgebiete" sx={{ variant: "links.nav" }}>
          Rechtsgebiete
        </NavLink>

        {/* <NavLink href="/team" sx={{ variant: "links.nav" }}>
          Team
        </NavLink> */}

        <NavLink href="/kontakt" sx={{ variant: "links.nav" }}>
          Kontakt
        </NavLink>

        {/* <NavLink
          href='/'
          sx={{ variant: 'links.nav' }}>
          Contact
      </NavLink> */}
      </div>

      <Switch
        isOn={value}
        // onColor="#f90"
        // handleToggle={() => setValue(!value)}
        handleToggle={(e) => {
          setColorMode(colorMode === "default" ? "dark" : "default");
          setValue(!value);
        }}
      />

      {/* <Button
        onClick={e => {
          setColorMode(colorMode === 'default' ? 'dark' : 'default')
        }}>
        {colorMode === 'default' ? 'Dark' : 'Light'}
      </Button> */}
    </header>
  );
};

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: '',
// }

export default Header;
