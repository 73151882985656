/** @jsx jsx */
import { jsx } from "theme-ui";
// import { Link } from "gatsby";

export default (props) => (
  <div>
    <img
      src={"/rae-logo-black.svg"}
      sx={{
        width: [200, 200, 240],
        mt: 3,
        mb: 2,
        mx: [0],
        pb: 1
        // border: '1px solid black'
      }}
      alt="Logo"
    />
  </div>
);
