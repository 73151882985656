/** @jsx jsx */
import { Styled, jsx } from "theme-ui";
import PropTypes from "prop-types";
import "normalize.css";

import Header from "./Header";
import Footer from "./Footer";

const Main = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <Styled.root sx={{}}>
      <Header />
      <main>{children}</main>
      <Footer />
    </Styled.root>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
